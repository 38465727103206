@import url(https://fonts.googleapis.com/css2?family=Nunito:ital@0;1&display=swap);
.common {
}

.common * {
  font-family: "Nunito", sans-serif !important;
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  overflow: hidden;
}

.container * {
  color: white !important;
}

.logo {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigation {
  -webkit-flex-grow: 2;
          flex-grow: 2;
  background-color: #9d78bf;
}

.side-navigation-panel-select-option {
  padding-left: 15px !important;
  border-width: 0px !important;
  border-left: 5px solid transparent !important;
}

.side-navigation-panel-select-option:hover {
  background-color: #503267 !important;
}

.side-navigation-panel-select-option-selected {
  border-left-color: white !important;
  background-color: transparent !important;
}

.side-navigation-panel-select-option-selected:hover {
  background-color: transparent !important;
}

.logout {
  width: 100%;
  padding-top: 15px;
  padding-left: 25px;
  padding-bottom: 15px;
  background-color: #503267;
  cursor: pointer;
}

